const burgerBtn = document.querySelector('.burger-btn');
const nav = document.querySelector('.nav__items');
const allNavItems = document.querySelectorAll('.nav__item');
let menuOpen = false;
const icons = document.querySelectorAll('.offer__box')
const footerYear = document.querySelector('.footer__year')

const handleNav = () => {
    nav.classList.toggle('nav__items--active')
    allNavItems.forEach(item => {
        item.addEventListener('click', () => {
            nav.classList.remove('nav__items--active')
            burgerBtn.classList.remove('burger-btn--open')
            menuOpen = false;
        } )
    })
    handleNavItemAnimation()
}
const handleNavItemAnimation = () => {
    let delayTime = 0;
    allNavItems.forEach(item => {
        item.classList.toggle('nav-items-animation')
        item.style.animationDelay = '.' + delayTime + 's';
        delayTime++
    })
}
const openMenu = (e) => {
    e.target
}



burgerBtn.addEventListener('click', () => {
    if (!menuOpen) {
        burgerBtn.classList.add('burger-btn--open')
        menuOpen = true
    } else {
        burgerBtn.classList.remove('burger-btn--open')
        menuOpen = false
    }
})

const handleYear = () => {
    const year = (new Date).getFullYear()
    footerYear.innerText = year
}

handleYear()
burgerBtn.addEventListener('click', handleNav)